import React from "react"
import { graphql, Link } from "gatsby"

//import { AnchorLink } from "gatsby-plugin-anchor-links"

import Hero from "../components/hero"
import Button from "../components/button"
import SEO from "../components/seo"
import TeachGrid from "../components/teachGrid"
import { MDXRenderer } from "gatsby-plugin-mdx"

import styled from "styled-components"


export const query = graphql`

query Educator {
    main: mdx(fileAbsolutePath: {regex: "/panel-0.md/"}) {
        frontmatter {
          heading
          hero {
            childImageSharp {
              fluid(maxWidth: 2500) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
          button1
          button1link
          button2
          button2link
        }
        body
      }
    workshops: mdx(fileAbsolutePath: {regex: "/panel-1.md/"}) {
        frontmatter {
          heading
          hero {
            childImageSharp {
              fluid(maxWidth: 2500) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
          button1
          button1link
          button2
          button2link
        }
        body
      }
    teaching: mdx(fileAbsolutePath: {regex: "/panel-2.md/"}) {
        frontmatter {
          heading
          hero {
            childImageSharp {
              fluid(maxWidth: 2500) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
          button1
          button1link
          button2
          button2link
        }
        body
      }
    presentations: mdx(fileAbsolutePath: {regex: "/panel-3.md/"}) {
        frontmatter {
          heading
          hero {
            childImageSharp {
              fluid(maxWidth: 2500) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
          button1
          button1link
          button2
          button2link
        }
        body
      }
}
`


const Content = styled.div`
margin-bottom: 2rem;
`


const Educator = ({ data }) => {

  const main = data.main
  // const workshops = data.workshops
  // const teaching = data.teaching
  // const presentations = data.presentations

  return (
    <>
      <SEO
        title="Educator"
        keywords={['music education', 'choir workshops', 'choral workshops', 'music teaching', 'singing workshop']}
      />
      <div style={{
        minHeight: '100vh',
      }}>
        <Hero imageSrc={main.frontmatter.hero.childImageSharp.fluid.src}>
          <h1>{main.frontmatter.heading}</h1>
        </Hero>

        {/* <ButtonContainer>
          <AnchorLink to="/educator#workshops">
            <Button>{workshops.frontmatter.heading}</Button>
          </AnchorLink>
          <AnchorLink to="/educator#teaching">
            <Button>{teaching.frontmatter.heading}</Button>
          </AnchorLink>
          <AnchorLink to="/educator#presentations">
            <Button>{presentations.frontmatter.heading}</Button>
          </AnchorLink> 
        </ButtonContainer>*/}
        <div>
          <Content>
            <MDXRenderer>
              {main.body}
            </MDXRenderer>
          </Content>
          <TeachGrid />
          {main.frontmatter.button1 &&
            <Link to={main.frontmatter.button1link}>
              <Button>
                {main.frontmatter.button1}
              </Button>
            </Link>
          }
          {main.frontmatter.button2 &&
            <Link to={main.frontmatter.button2link}>
              <Button>
                {main.frontmatter.button2}
              </Button>

            </Link>
          }
          {/* <Content>
        {workshops.body}
        </Content> */}
        </div>
      </div>
      {/*       
      <PageContainer id="workshops">
        <Hero imageSrc={workshops.frontmatter.hero.childImageSharp.fluid.src}>
          <h2>{workshops.frontmatter.heading}</h2>
        </Hero>
        <Content>
          {workshops.body}
        </Content>
        {workshops.frontmatter.button1 &&
            <Link to={workshops.frontmatter.button1link}>
              <Button>
                {workshops.frontmatter.button1}
              </Button>
            </Link>
          }
          {workshops.frontmatter.button2 &&
            <Link to={workshops.frontmatter.button2link}>
              <Button>
                {workshops.frontmatter.button2}
              </Button>

            </Link>
          }
      </PageContainer>
      <PageContainer id="teaching">
        <Hero
          imageSrc={teaching.frontmatter.hero.childImageSharp.fluid.src}
        >
          <h2>{teaching.frontmatter.heading}</h2>
        </Hero>
        <Content>
          {teaching.body}
        </Content>
        {teaching.frontmatter.button1 &&
            <Link to={teaching.frontmatter.button1link}>
              <Button>
                {teaching.frontmatter.button1}
              </Button>
            </Link>
          }
          {teaching.frontmatter.button2 &&
            <Link to={teaching.frontmatter.button2link}>
              <Button>
                {teaching.frontmatter.button2}
              </Button>

            </Link>
          }
        <TeachGrid />
      </PageContainer> */}

      {/* <PageContainer id="presentations">
        <Hero imageSrc={presentations.frontmatter.hero.childImageSharp.fluid.src}>
          <h2>{presentations.frontmatter.heading}</h2>
        </Hero>
        <Content>
          {presentations.body}
        </Content>
        {presentations.frontmatter.button1 &&
            <Link to={presentations.frontmatter.button1link}>
              <Button>
                {presentations.frontmatter.button1}
              </Button>
            </Link>
          }
          {presentations.frontmatter.button2 &&
            <Link to={presentations.frontmatter.button2link}>
              <Button>
                {presentations.frontmatter.button2}
              </Button>

            </Link>
          }
      </PageContainer> */}
    </>
  )
}

export default Educator

